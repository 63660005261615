<template>
  <div
    v-if="credits.length"
    class="titleRow"
  >
    Съёмочная группа
  </div>
  <Swiper
    :navigation="true"
    :modules="[Navigation]"
    :breakpoints="commonBreakPoints"
    :slides-per-view="channelSlidesPerView"
    :space-between="spaceBetween"
    class="poster_detail__actor_block"
  >
    <SwiperSlide
      v-for="actor in credits"
      :key="actor.name"
      class="program_card flex pointer"
      @click="navigateTo({ name: 'actor-name', params: { name: actor.search_text } })"
    >
      <div class="poster_detail__actor_initials flex">{{ actor.initials }}</div>
      <div>
        <div
          class="poster_detail__actor_name"
          v-html="actor.name"
        ></div>
        <div class="poster_detail__actor_role">{{ actor.role }}</div>
      </div>
    </SwiperSlide>
  </Swiper>
</template>
<script setup>
import { Swiper, SwiperSlide } from 'swiper/vue';
import { Navigation } from 'swiper';
import insertBrTagBetweenWords from '@/utils/ui/insertBrTagBetweenWords';
import { commonBreakPoints } from '@/components/ui/breakPoints';

const props = defineProps({
  program: {
    type: Object,
    required: true,
    default: () => ({}),
  },
});

const { channelSlidesPerView, spaceBetween } = storeToRefs(usePosterDetailsStore());

const credits = computed(() => {
  const p = props.program?.credits || {};
  const creds = [];
  const psh = (a, role) => {
    for (let p of a) {
      creds.push({
        name: insertBrTagBetweenWords(p),
        initials: buildInitials(p),
        role: { director: 'Режиссёр', actor: 'Актёр', presenter: 'Ведущий' }[role],
        search_text: p,
      });
    }
  };

  for (let r of ['director', 'actor', 'presenter']) {
    psh(p[r] || [], r);
  }
  return creds;
});

function buildInitials(actor) {
  const words = actor.split(' ');
  let firstInitial = words[0][0];
  if (words.length > 1) firstInitial += words[words.length - 1][0];
  return firstInitial.toUpperCase();
  // return actor.split(' ').map(word => word[0]).join('').toUpperCase();
}
</script>
