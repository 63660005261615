// export default function insertBrTagBetweenWords(str) {
//   const words = str.split(' ');
//
//   // todo: Вставляем <br> между каждым словом (кроме последнего)
//   const result = words.reduce((acc, word, index) => {
//     // Если это не последнее слово, добавляем <br>
//     if (index < words.length - 1) {
//       acc.push(word, '<br>');
//     } else {
//       acc.push(word);
//     }
//     return acc;
//   }, []);
//
//   return result.join('');
// }

export default function insertBrTagBeforeLastWord(str) {
  const words = str.split(' ');
  // todo: Вставляем <br> перед последним словом (если строка содержит более одного слова)
  if (words.length > 1) words.splice(-1, 0, '<br>');
  return words.join(' ');
}
